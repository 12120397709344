import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageBody from 'components/shared/pageBody'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'

import React from 'react'

const Access = () => {
  const meta = {
    title: 'Access | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <MaxWidth $wooden>
        <PageBody>
          <h1 className="mt-4">Access</h1>
<p>Every performance is now audio described - if you would like to use this facility, please call +4420 7836 1443 or email&nbsp;<a href="mailto:access@stmartinstheatre.co.uk">access@stmartinstheatre.co.uk</a>&nbsp;to reserve your head sets. The audio description is pre-recorded and has been produced by VocalEyes and includes introductory notes happening 10 minutes before the play starts.</p>
<p>Patrons with specific access or seating requirements should book with the Box Office direct on +4420 7836 1443. Bookings for specific seats should be made early to avoid disappointment. Bookings and enquiries can also be taken by email at&nbsp;<a href="mailto:access@stmartinstheatre.co.uk">access@stmartinstheatre.co.uk</a>.</p>
<p>The St. Martin&rsquo;s Theatre is located in West Street. It is situated next to the Ambassadors Theatre and opposite the Ivy Restaurant. There are three shallow steps from the pavement to swing doors through to the foyer, where you will find the box office to your left and a souvenir kiosk to your right. There are five steps to the back of the dress circle auditorium, where there are two steps between rows. All staircases have handrails and are highlighted. Access to the Stalls and Upper Circle are not suitable for patrons with walking difficulties.</p>
<p>Guide dogs and hearing dogs are permitted in the auditorium and staff can dog sit by prior arrangement. Dogs will be looked after in the manager&rsquo;s office. Please inform the box office at the time of booking.&nbsp;</p>
<p>Wheelchair access is via a removable ramp through the main entrance &ndash; staff will be available to assist. There are two spaces for wheelchairs, one in Box C and one in the dress circle.</p>
<p>It is possible to transfer from a wheelchair to an aisle seat in the dress circle.</p>
<p>All of the bars are accessed via stairs. Drinks in plastic cups may be taken into the auditorium.</p>
<p>There is an adapted toilet on the Dress Circle level. Access is via the entrance on Tower Court. Please ask a member of staff, who will be happy to assist with access to the adapted toilet facilities.</p>

        </PageBody>
      </MaxWidth>
    </Layout>
  )
}

export default Access
